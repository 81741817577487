const admin = [
  {
    header: "Admin",
    resource: "Admin",
  },
  {
    title: "Add Sale",
    route: "addSale",
    icon: "DollarSignIcon",
    resource: "Admin",
  },
  {
    title: "Customers",
    route: "adminCustomers",
    icon: "UsersIcon",
    resource: "Admin",
  },
];

const agent = [
  // {
  //   title: "Announcements",
  //   route: "home",
  //   icon: "HomeIcon",
  //   resource: "Home",
  //   action: "read",
  // },
  // {
  //   header: "User",
  //   resource: "Agent",
  // },
  // {
  //   title: "Profile",
  //   route: "profile",
  //   icon: "UserIcon",
  //   resource: "Agent",
  // },
  // {
  //   title: "Page",
  //   route: "page",
  //   icon: "LayoutIcon",
  //   resource: "Agent",
  // },
  // {
  //   header: "Application",
  //   resource: "Agent",
  // },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    resource: "Agent",
    action: "read",
  },
  {
    title: "Customers",
    route: "customers",
    icon: "UsersIcon",
    resource: "Agent",
  },
  {
    title: "Add Customer",
    route: "addCustomer",
    icon: "PlusIcon",
    resource: "Agent",
  },
  {
    title: "Jobs",
    route: "allJobs",
    icon: "PlusIcon",
    resource: "Agent",
  },
  {
    title: "API Keys",
    route: "apiKeys",
    icon: "KeyIcon",
    resource: "Agent",
  },
  // {
  //   header: "Sale",
  //   resource: "Agent",
  // },
  // {
  //   title: "Referral Summary",
  //   route: "referralSummary",
  //   icon: "FileTextIcon",
  //   resource: "Agent",
  // },
  // {
  //   title: "Sale Summary",
  //   route: "saleSummary",
  //   icon: "DollarSignIcon",
  //   resource: "Agent",
  // },
  // {
  //   title: "Settlements",
  //   route: "settlements",
  //   icon: "MonitorIcon",
  //   resource: "Auth",
  //   action: "read",
  // },
];

const common = [
  {
    header: " ",
    resource: "Auth",
    action: "read",
  },
  {
    title: "Log Out",
    route: "logout",
    icon: "LogOutIcon",
    resource: "Auth",
    action: "read",
  },
];

export default [...agent, ...admin, ...common];
